@use "./variables" as *;
$mobile-width: 767px;

@mixin mobile {
    @media (max-width: $mobile-width) {
        @content;
    }
}

// tablet sizing mixin
@mixin tablet {
    @media screen and (min-width: $tablet-screen-min) {
        @content;
    }
}


// desktop mixin
@mixin desktop {
    @media screen and (min-width: $desktop-screen-min) {
        @content;
    }
}