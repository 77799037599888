@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

.footer {
    height: 100px;
    background-color: $background-black;
    color: $secondary-white;

    @include tablet {
        height: 130px;
        padding: 1rem;
    }

    @include desktop {
        height: 190px;
    }

    &__container {
        width: 100%;
        height: 100%;
        padding: 0rem 1.25rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include tablet {
            width: 65%;
        }

        @include desktop {
            width: 80%;
            margin: auto;
        }
    }

    &__contact {
        &-list {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: space-around;
            padding-bottom: 1rem;

            @include tablet {
                width: 80%;
            }

            @include desktop {
                width: 50%;
            }
        }

        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            width: 40px;
            height: 40px;
            border: 2px solid $primary-blue;
            border-radius: 50%;
            font-size: 20px;
            transition: 0.5 ease;

            color: $primary-blue;

            &:hover {
                background: $primary-blue;
                box-shadow: 0 0 20px $primary-white;
                z-index: 999;

                .footer__contact-icon {
                    color: $background-black;
                    fill: $background-black;
                }

                .git {
                    fill: $primary-blue;
                }
            }
        }

        &-icon {
            width: 25px;
            height: 25px;
            color: $primary-blue;
        }

        &-qr {
            width: 10px;
            height: 10px;
        }

        &-img {
            width: 100%;
            height: 100%;
        }
    }

    &__copyright {

        @include tablet {
            padding: 1rem;
        }
    }
}