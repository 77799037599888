@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

// padding
header {
    padding: 0rem 1rem;
    border-bottom: 1px solid black;
    background-color: $background-gray-opacity;
    transition: all 1s;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;

}

.background-thick {
    transition: all 1s;
    background-color: $background-gray;
}

.header-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    // @include tablet{
    //     padding: ;
    // }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        @include tablet {
            width: 50%;
            padding-left: 1rem;
        }
    }

    &__link {
        color: $nav-white;

        // @include tablet {
        //     margin: 0%;
        // }
    }

    &__fname {
        color: rgba(255, 255, 255, 0.6);
    }

    &__lname {
        color: rgba(255, 255, 255, 1);
    }

    &__link-wrapper {
        display: flex;

        @include tablet {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-end;
            width: 60%;
        }

        @include desktop {
            width: 30%;
        }

        &-tablet {
            display: none;

            @include tablet {
                display: inline-flex;
                width: 40%;
            }

            @include desktop {
                width: 30%;
            }
        }
    }

    .show {
        height: 40px;
        max-height: 9999px;
        animation: 2.1s fadeIn;
        transition: all 1s cubic-bezier(0, 0, 1, 1);
        display: flex;
        justify-content: center;

    }

    .not-show {
        height: 0px;
        overflow: hidden;
        animation: 2.1s fadeOut;
        transition: all 1s cubic-bezier(0, 0, 1, 1);
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        80% {
            visibility: hidden;
        }

        100% {
            visibility: visible;
            opacity: 1;
        }
    }

    &__li {
        display: flex;
        justify-content: center;
        list-style: none;
        width: 30%;
        height: 100%;
        align-items: flex-end;

        &-link {
            color: $nav-white;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: $primary-blue;
            }

            @include tablet {
                height: 100%;
                padding: 1.2rem 0.75rem;
            }
        }
    }

    &__collapse {
        text-align: center;

        @include tablet {
            display: none;
        }

        @include desktop {
            display: none;
        }
    }
}

.navbar-collapse {

    @include tablet {
        display: none;
    }
}

.menu-icon {
    display: inline-block;
    cursor: pointer;

    @include tablet {
        display: none;
    }

    &__bar1,
    &__bar2,
    &__bar3 {
        width: 28px;
        height: 4px;
        background-color: rgba(255, 255, 255, 0.8);
        margin: 6px 0;
        transition: 0.5s;
    }

    .change1 {
        transform: translate(0, 10px) rotate(45deg);
    }

    .change2 {
        opacity: 0;
    }

    .change3 {
        transform: translate(0, -10px) rotate(-45deg);
    }
}

.active {
    color: $primary-blue;
    font-weight: bold;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        border-bottom: 3px solid $primary-blue;
        width: 100%;
        padding-bottom: 1.4rem;
    }

    @include tablet {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 4px solid $primary-blue;
            width: 100%;
            padding-bottom: 3.5rem;
            animation: 0.8s left;
            animation-fill-mode: both;

            @include tablet {
                animation-fill-mode: forwards;
            }

            @include desktop {
                animation-fill-mode: both;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            border-top: 4px solid $primary-blue;
            width: 100%;
            animation: 0.8s right;
            animation-fill-mode: both;

            @include tablet {
                animation-fill-mode: forwards;
            }

            @include desktop {
                animation-fill-mode: both;
            }
        }

        @keyframes left {
            0% {
                width: 0%;
                opacity: 0;
            }

            100% {
                width: 100%;
                opacity: 1;
            }
        }

        @keyframes right {
            0% {
                width: 0%;
                opacity: 0;
            }

            100% {
                width: 100%;
                opacity: 1;
            }
        }
    }
}