@use './mixins' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

h1 {
    color: $primary-white;
    font-size: 1.75rem;

    @include tablet {
        font-size: 2.75rem;
    }
}