@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;


.title {
    background-color: $background-black;
    text-align: center;
    color: $primary-white;
    padding-top: 3.5rem;

    @include tablet {
        padding-bottom: 2rem;
    }
}

.contacts {
    padding-top: 3rem;

}

.contact {
    background-color: $background-black;
    width: 100%;

    padding: 2rem 1.25rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    color: $primary-white;

    @include tablet {
        padding: 2.5rem 3rem;
    }

    @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-direction: row-reverse;
        padding: 2.5rem 5rem;
    }



    &-form {
        display: flex;
        width: 100%;
        height: 80%;
        flex-direction: column;

        @include desktop {
            display: flex;
            flex-direction: column;
            width: 40%;
            height: 100%;
            margin-top: 1.5rem;
        }

        &__header {
            padding-top: 1.5rem;
            font-weight: 500;

            @include tablet {
                font-weight: 600;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__label {
            color: $secondary-white;
            line-height: 1.75rem;
            padding-top: 0.75rem;
        }

        &__input {
            border-radius: 5px;
            background-color: $background-gray;
            border: 1px solid gray;
            line-height: 1.75rem;
            height: 50px;
            padding: 1rem;
            color: $primary-white;

            overflow-y: auto;
            overflow-x: auto;

            &:focus {
                outline: 2px solid $primary-purple;
                background-color: $background-gray;

                z-index: 999;
                border-radius: 5px;
            }

        }

        &__message {
            height: 200px;
            padding: 1rem;
        }

        &__submit {
            width: 100%;
            border-radius: 5px;
            background-color: $background-black;
            padding: 1rem;
            margin-top: 2.25rem;
            border: 2px solid $primary-purple;
            color: $secondary-white;

            &:hover {
                background-color: $darker-purple;
                color: $primary-white;
                z-index: 999;
            }

            @include desktop {
                background: linear-gradient(to right, $darker-purple 50%, $background-black 50%);
                background-size: 200% 100%;
                background-position: right bottom;
                transition: all .4s ease-out;

                &:hover {
                    background-position: left bottom;
                    color: $primary-white;
                    z-index: 999;
                }
            }

            &:active {
                transform: scale(.90);
            }
        }
    }

    &-map {
        display: flex;
        width: 100%;
        height: 300px;
        position: relative;

        @include tablet {
            display: flex;
            height: 500px;

        }

        @include desktop {
            display: flex;
            width: 55%;
            height: 600px;

        }

        &__wrapper {
            width: 100%;
            height: 100%;
            display: flex;

            @include desktop {
                justify-content: flex-start;
            }
        }

        &__iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 0px;
            border-radius: 1rem;
            touch-action: pan-x pan-y;
        }
    }

    &-info {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0.5rem;

        width: 80%;
        max-width: 350px;
        height: 30%;
        background-color: $background-black;
        opacity: 0.8;


        border-radius: 8px;
        left: 50%;
        top: 80%;
        transform: translate(-50%, -50%);


        @include tablet {
            width: 80%;
            max-width: 450px;
        }

        @include desktop {
            width: 80%;
            max-width: 650px;
            height: 20%;
            left: 6%;
            top: 77%;
            transform: translateX(0%);
            padding-top: 0.5rem;

            flex-direction: row;
            align-items: baseline;
        }

        &__title {
            font-size: 0.6rem;
            line-height: 20px;

            @include tablet {
                font-size: 1rem;
                line-height: 28px;
            }
        }

        &__email {
            color: $primary-purple;
            font-weight: 700;

            @include tablet {
                line-height: 1.5rem;
            }
        }
    }

    &__text {
        font-size: 0.6rem;


        @include tablet {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}