@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../components/Footer/Footer.scss' as *;

.projects {
    background-color: $background-black;
    padding: 2.5rem 1.25rem;
    height: 100%;

    @include desktop {
        padding: 2.5rem 5rem;
    }


    &__title {
        padding: 3.5rem 0rem;
        text-align: center;
    }

    &__description {
        color: $secondary-white;
        text-align: center;
        padding-bottom: 1rem;
    }

    &__list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-radius: 10px;


        @include tablet {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-around;
            padding: 1rem;
        }
    }

    @keyframes in {
        0% {
            transform: scale(1.0);
        }

        100% {
            transform: scale(1.02);
        }
    }

    @keyframes out {
        0% {
            transform: scale(1.02);
        }

        100% {
            transform: scale(1.0);
        }
    }

    &__item {
        width: 100%;
        height: 250px;
        position: relative;
        color: $secondary-white;
        text-decoration: none;
        border: 3px solid $secondary-white;
        border-radius: 12px;
        margin: 1.5rem 0rem;

        animation-name: out;
        animation-duration: 0.4s;

        &:hover {
            color: $secondary-white;
            animation-name: in;
            animation-duration: 0.4s;
            animation-fill-mode: forwards;

            .projects__background {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 0.5rem;
                background-color: $background-black;
                width: 100%;
                height: 100%;
                top: 0;

                @include tablet {
                    padding: 0.75rem 1.125rem;
                }
            }
        }

        @include tablet {
            width: 47%;
            height: 300px;
            margin: 2.5rem 0rem;
        }

        @include desktop {
            width: 45%;
            height: 400px;
        }
    }

    &__image {
        border-radius: 10px;
        height: 100%;
        line-height: 0;
    }

    &__img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }


    &__background {
        position: absolute;
        top: 10px;
        display: none;
        border-radius: 10px;
        overflow: scroll;
    }

    &__overlay {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__project {

        &-subtitle {
            color: $primary-blue;
            font-size: 1.125rem;

            @include tablet {
                font-size: 1.5rem;
            }

            @include desktop {
                padding: 1rem 0rem;
            }
        }

        &-title {
            font-size: 1.25rem;

            @include tablet {
                font-size: 2rem;
                padding: 0.5rem 0rem;
            }

            @include desktop {
                font-size: 2.5rem;
            }
        }

        &-description {
            padding: 0.5rem 0rem;
            font-size: 1rem;

            @include tablet {
                padding: 1rem 0rem;
                font-size: 1.125rem;
            }

            @include desktop {
                font-size: 1.5rem;
            }
        }
    }


    &__links {
        margin: 0.5rem auto;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        @include tablet {
            width: 65%;
        }

        @include desktop {
            width: 50%;
        }

    }

    &__link {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__icon-website {
        width: 30px;
        height: 30px;
        color: $primary-blue;

        &:active {
            transform: scale(0.9);
        }


        @include tablet {
            width: 50px;
            height: 50px;
        }
    }
}

//extend feature from Footer.scss
.link-git {
    @extend .footer__contact-item;
    width: 30px;
    height: 30px;

    &:hover {
        background: $primary-blue;
        box-shadow: 0 0 20px $primary-blue;
        z-index: 999;

        .link-git {
            color: $background-black;
            fill: $background-black;
        }

        .projects__icon-git {
            fill: $primary-blue;
        }
    }

    @include tablet {
        width: 50px;
        height: 50px;
    }

}

.projects__icon-git {
    @extend .footer__contact-icon;
    width: 15px;
    height: 15px;
    color: $primary-blue;

    @include tablet {
        width: 30px;
        height: 30px;
    }
}