@use "./styles/partials/reset" as *;
@use "./styles/partials/typography" as *;
@use "./styles/partials/variables" as *;
@use "./styles/partials/mixins" as *;
@use "./styles/partials/global" as *;

.App {
    z-index: 100;
    background-color: $background-black;
    cursor: url('./../public/logo192.png'), auto;
}