@use './mixins' as *;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,200;1,300&display=swap');

* {
    font-family: 'Raleway', sans-serif;
}

h1 {

    line-height: 36px;
    font-size: 1.75rem;
    font-weight: 600;


    @include tablet() {
        line-height: 40px;
        font-size: 2rem;
        font-weight: 600;
    }
}

h2 {

    line-height: 28px;
    font-size: 1.5rem;
    font-weight: 600;


    @include tablet() {

        line-height: 32px;
        font-size: 1.75rem;
        font-weight: 600;
    }
}

h3 {
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;


    @include tablet() {

        line-height: 22px;
        font-size: 0.875rem;
        font-weight: 600;
    }
}


p {


    line-height: 26px;
    font-size: 1rem;
    font-weight: 400;


    @include tablet() {

        line-height: 28px;
        font-size: 1.25rem;
        font-weight: 400;
    }
}

a {
    font-size: 1.125rem;

    @include tablet() {

        font-size: 1.25rem;
    }
}