@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

.about {
    background-color: $background-black;
    background: $background-black;
    color: $primary-white;
    height: 100vh;
    display: flex;
    align-items: center;


    &__wrapper {
        width: 100%;
        height: auto;
        align-items: center;
        margin-right: auto;

        @include tablet {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 3rem;
        }

        @include desktop {
            margin-top: 7rem;
        }

    }

    &__content-1 {
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 4rem;
        padding-top: 3rem;

        @include tablet {
            width: 80%;
            padding-top: 0rem;
        }
    }

    &__title {
        line-height: 3rem;
        margin: 1rem 0rem;
        font-size: 3rem;
        height: auto;

        &-position {
            background-image: linear-gradient(to right, #b17ad7, #978fe9, #7ea2f4, #6ab2f7, #64c0f4);
            background-clip: text;
            color: transparent;
        }

        @include desktop {
            font-size: 4rem;
        }
    }

    &__detail {
        width: 90%;
        display: inline-block;

        @include tablet {
            width: 80%;
        }

        &-text {
            line-height: 1.625;
            margin-bottom: 2rem;
            padding: 1rem;
            line-height: 35px;
            text-align: center;
            vertical-align: text-bottom;

            &::after {
                content: "|";
                animation: blink .75s step-end infinite;
            }

            @keyframes blink {

                from,
                to {
                    color: transparent
                }

                50% {
                    color: rgba(245, 245, 245)
                }
            }
        }
    }

    &__link {
        display: flex;
        justify-content: center;


        &-contact {
            color: $secondary-white;
            font-size: 1.125rem;
            line-height: 1.75rem;
            padding: 0.5rem 1.5rem;
            margin: 1rem;

            border-width: 0px;
            border-radius: 1rem;
            height: 5rem;
            display: flex;
            align-items: center;

            border: 2px solid $primary-purple;

            &:hover {
                background-color: $darker-purple;
                color: $primary-white;
                box-shadow: 0 0 20px $nav-white;
                z-index: 999;
            }

            &:active {
                transform: scale(.90);
            }

            @include desktop {

                background: linear-gradient(to right, $darker-purple 50%, $background-black 50%);
                background-size: 200% 100%;
                background-position: right bottom;
                transition: all .4s ease-out;

                &:hover {
                    background-position: left bottom;
                    color: $primary-white;
                    box-shadow: 0 0 20px $nav-white;
                    z-index: 999;
                }
            }


        }

        &-projects {
            @extend .about__link-contact;
            margin-left: 1rem;


            @include desktop {
                background: linear-gradient(to left, $darker-purple 50%, $background-black 50%);
                background-size: 200% 100%;
                background-position: left bottom;
                transition: all .4s ease-out;

                &:hover {
                    background-position: right bottom;
                    color: $primary-white;
                }
            }

        }
    }

    &__content-2 {}

    &__img {
        margin: auto;
        object-position: center;
        object-fit: cover;
        max-width: 100%;
        height: auto;

    }
}