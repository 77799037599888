// phone sizing
$phone-section-padding: 0rem 1rem 0;

// tablet sizing
$tablet-screen-min: 767px;
$tablet-section-padding: 0rem 2rem 0;

//desktop sizing
$desktop-screen-min: 1280px;
$desktop-section-padding: 0 auto;

$primary-white: rgba(255, 255, 255, 1);
$secondary-white: rgba(156, 163, 175, 1);
$background-black: rgb(17 24 39);
$background-black-opacity: rgba(17, 24, 39, 0.5);
$background-green: rgba(16, 185, 129, 1);
$background-gray: rgba(31, 41, 55, 1);
$background-gray-opacity: rgba(31, 41, 55, 0.9);
$nav-white: rgba(255, 255, 255, 0.65);
$primary-purple: rgb(120, 112, 230);
$darker-purple: rgb(86, 62, 192);
$primary-blue: #7ea2f4;