@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

.skills {
    background-color: $background-black;
    padding-top: 3.5rem;

    &__container {
        padding: 2.5rem 1.25rem;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @include tablet {
            padding: 2.5rem 3rem;
        }

        @include desktop {
            padding: 2.5rem 5rem;
        }
    }

    &__text {
        text-align: center;
        margin-bottom: 20px;

        @include tablet {
            margin-bottom: 50px;
        }
    }

    &__title {
        line-height: 2.25rem;
        margin-bottom: 1rem;
        color: $primary-white;
    }

    &__description {

        line-height: 1.625;
        margin-bottom: 2rem;
        color: $secondary-white;
        text-align: center;

        @include desktop {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        @include tablet {
            margin-left: auto;
            margin-right: auto;
        }

        @include desktop {
            width: 100%;
        }
    }

    &__item {
        padding: 0.5rem;
        width: 100%;

        @include tablet {
            width: 50%;
        }

        &-content {
            background-color: #1f2937;
            border-radius: 0.375rem;
            padding: 1rem;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &-icon {
            margin-right: 1rem;
        }

        &-title {
            font-size: 1.125rem;
            font-weight: 500;
            color: #fff;
        }
    }
}